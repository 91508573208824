import React, { useState } from "react";
import { ScreenContainer, ContentContainer } from "./Elements";
import BackgroundSlider from "components/custom/BackgroundSlider";
import { bgcm1, bgcm2, bgcm3 } from "helpers/assets";
import { StepperTransition } from "helpers/transitions";
import { AnimatePresence } from "framer-motion";
import SectionContent from "components/custom/SectionContent";
const BACKGROUNDS = [bgcm1, bgcm2, bgcm3];

const SteemSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <ScreenContainer id="steems" className="container">
      <ContentContainer>
        <AnimatePresence mode="wait" key="steem">
          {activeIndex === 0 && (
            <SectionContent
              key="BGCM-FIRST"
              title="BGCM"
              description="Block Game Change Makers (BGCM)™ is a John Wall Family Foundation (JWFF) program to introduce young people to concepts in science, technology, engineering, entrepreneurship, and math (STEEM)™ through exciting topics such as esports, robotics, and space. 
              The program is engaging and interactive, using hands-on activities and real-world examples to help students understand complex concepts. Students in the program work on projects related to designing and building robots and, in the future, will be learning the skills they need to manage eSports events and the basics of creating video games. The program also exposes students to career paths in STEEM fields and provides mentorship opportunities with industry professionals. Overall, BGCM™ will inspire and educate the next generation of STEEM™ leaders and innovators."
              cta="Volunteer"
              href="https://calendly.com/jwff"
              {...StepperTransition}
            />
          )}

          {activeIndex === 1 && (
            <SectionContent
              key="BGCM-SECOND"
              title="BGCM"
              description="Block Game Change Makers (BGCM)™ is not only about technology. We are advocates for well-being and physical exercise. BGCM™ inspires and empowers the next generation to leverage rapidly evolving technology to help address challenges in their communities. We teach participants about intellectual property for digital products to help them understand how to create generational wealth. We aligned our program with the United Nations (UN) Sustainability Development Goals (SDG) to help students truly understand the concept of acting at the local level while thinking globally because the next generation can change the world if given the opportunity."
              cta="Volunteer"
              href="https://calendly.com/jwff"
              {...StepperTransition}
            />
          )}
          {activeIndex === 2 && (
            <SectionContent
              key="BGCM-THIRD"
              title="BGCM"
              description="Block Game Change Makers (BGCM)™ will help the next generation, focusing on under represented communities, learn innovative ways to create generational wealth with science, technology, engineering, entrepreneurship, and math (STEEM)™. The current focus is on eSports event management, cultural anime with both physical & digital products, and video game development. The global anime market was 24.8 billion USD in 2021, and the global video game market is estimated to reach 310 billion USD in 2023. Our mission is to bring knowledge, insight, and opportunity to the participants in our program. Extended Reality (XR) is also on our BGCM™ roadmap. Physical activity and immersion are critical for well-being and maintaining a suitable fitness level. BGCM™ will always focus on mental and physical well-being for all program participants as this is a core JWFF value."
              cta="Volunteer"
              href="https://calendly.com/jwff"
              {...StepperTransition}
            />
          )}
        </AnimatePresence>
      </ContentContainer>
      <BackgroundSlider
        backgrounds={BACKGROUNDS}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        from="STEEM"
      />
    </ScreenContainer>
  );
};

export default SteemSection;
